import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  isLoading = false;
  checkAllDone = true;
  pageTitle: string;

  routeId = JSON.parse(localStorage.getItem('currentRouteId'));
  currentRoute: any;
  currentRoutePoints: any;
  activePointsAndVariations: any;
  currentPointEdit: number = 0;

  placesService: google.maps.places.PlacesService;

  googleInfos: any = [];

  titles: any = [];
  descriptions: any = [];
  moreinfos: any = [];
  youtubes: any = [];
  categories: any = [];
  urls: any = [];

  files: any[] = [];
  filesSound: any[] = [];
  filesVideo: any[] = [];
  videoType: any = [];
  videoCode: any = [];

  allPointsCategories: any = [];

  cropImg: boolean = false;
  imgToCrop: any;
  croppingFile: any;

  allQuizzs: any;

  constructor(private http: HttpClient, private apiService: ApiService, private router: Router, private ref: ChangeDetectorRef, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPoints();
    this.getCategories();
  }

  getPoints() {
    this.isLoading = true;
    this.apiService.getRoute(this.routeId).subscribe(
      data => {
        this.currentRoute = data.data;
        this.currentRoutePoints = this.currentRoute.points;
        this.pageTitle = 'Édition du circuit ' + this.currentRoute.title;
        let pointsAndVariations = [...this.currentRoute.points, ...this.currentRoute.variations];    
        this.activePointsAndVariations = pointsAndVariations.sort((a, b) => a.pivot.order - b.pivot.order);
        this.checkIfAllDone();
        this.activePointsAndVariations.forEach((element, index) => {
          this.files.push([]);
          this.filesSound.push([]);
          this.filesVideo.push([]);
          this.videoCode.push('');
          this.videoType.push('');
          this.googleInfos.push({});
          if (index == 0) {
            this.getPointsGoogleDetails(element, index);
          }
          (element.title) ? this.titles.push(element.title) : this.titles.push("");
          (element.description) ? this.descriptions.push(element.description) : this.descriptions.push("");
          (element.moreinfo) ? this.moreinfos.push(element.moreinfo) : this.moreinfos.push("");
          (element.youtube) ? this.youtubes.push(element.youtube) : this.youtubes.push("");
          (element.catpoint_id) ? this.categories.push(element.catpoint_id) : this.categories.push("");
          (element.url) ? this.urls.push(element.url) : this.urls.push("");
          if (element.medias.length) this.populateMedias(element.medias, index);
          if (element.video_codes.length) {
            this.videoCode[index] = element.video_codes[0].code;
            this.videoType[index] = element.video_codes[0].type;
          }
        });
        this.getQuizzs();
        this.isLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  getQuizzs() {
    this.apiService.listQuizz(this.routeId).subscribe(
      data => {
        this.allQuizzs = data.data;
        this.allQuizzs.forEach(quizz => {
          let point;
          if (quizz.variation_id) {
            point = this.activePointsAndVariations.find(x => x.id === quizz.variation_id);
          } else {
            point = this.activePointsAndVariations.find(x => x.id === quizz.point_id);
          }
          if (point) point.quizz = quizz;      
        });        
      },
      err => {
        console.log(err);
      }
    );
  }

  getPointsGoogleDetails(element, index) {
    let request;
    if (!element) return;
    if (!(element.googleplace_id || element.point_id)) return;

    if (element.googleplace_id) {
      request = { placeId: element.googleplace_id };
    } else {
      let parentPoint = this.activePointsAndVariations.filter(obj => {
        return obj.id === element.point_id
      });
      if (parentPoint.googleplace_id) {
        request = { placeId: parentPoint.googleplace_id };
      } else {
        return;
      }
    } 
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.placesService.getDetails(request, (place, status) => {
      if (status === 'OK') {
        
        if (place.name) this.googleInfos[index].title = place.name;
        if (place.formatted_address && place.opening_hours) {
          this.googleInfos[index].more = place.formatted_address + '\n\n' + place.opening_hours.weekday_text;
        } else if (place.formatted_address) {
          this.googleInfos[index].more = place.formatted_address;
        } else if (place.opening_hours) {
          this.googleInfos[index].more = place.opening_hours.weekday_text;
        }
        if (place.photos) {
          var photos = place.photos;
          var urls = []; // we will store the urls here
          photos.slice(0, 6).forEach((photo) => {
            urls.push(
              photo.getUrl({
                maxWidth: 800,
                maxHeight: undefined
              })
            );
          });
          this.googleInfos[index].medias = urls;
          this.detectChanges();
        }
      }
    });
  }

  populateMedias(elements, index) {
    elements.forEach((element) => {
      var blob = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.url);
      xhr.responseType = "blob";
      xhr.onload = () => 
      {
          blob = xhr.response;
          let format = element.url.substring(element.url.lastIndexOf('.') + 1);
          let name = element.url.substring(element.url.lastIndexOf('/') + 1);    
          var file = new File([blob], name, {type: "image/" + format, lastModified:new Date().getTime()});
          if (element.url.slice(element.url.length - 3) == 'mp3') {
            this.filesSound[index].push(file);
          } else {
            this.files[index].push(file);
          }
      }
      xhr.send();
    });
  }

  getCategories() {
    this.apiService.listCatpoint().subscribe(
      data => {
        this.allPointsCategories = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  onCopyData(data, type, index) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    if (type == 'title') this.titles[index] = data;
    if (type == 'description') this.descriptions[index] = data;
    if (type == 'moreinfo') this.moreinfos[index] = data;
  }

  onCopyImage(targetUrl) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    const proxyUrl = "https://cors.belooga-inc.ca/raw?url=";
    this.http.get(targetUrl, {responseType: 'blob'}).subscribe(data => {
      let extension = data.type.split('/')[1];
      let name = 'from-google-' + (+new Date).toString(36).slice(-5) + '.' + extension;
      let file = new File([data], name, {type: data.type, lastModified: +new Date});
      this.files[this.currentPointEdit].push(file);
      this.detectChanges();
    },
    err => {
      console.log(err);
    });
  }

  onSelect(event) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    let files = event.addedFiles;
    files.forEach(file => {
      this.files[this.currentPointEdit].push(file);
    });
  }

  onCropImg(file) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.imgToCrop = file;
    this.cropImg = true;
    this.croppingFile = file;
  }

  onCroppedImg(e) {
    if (e) {
      if (this.files[this.currentPointEdit].indexOf(this.croppingFile) !== -1) {
        this.files[this.currentPointEdit].splice(this.files[this.currentPointEdit].indexOf(this.croppingFile), 1);
        this.files[this.currentPointEdit].push(e);
      }
    }
    this.cropImg = false;
  }

  onRemove(event) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.files[this.currentPointEdit].splice(this.files[this.currentPointEdit].indexOf(event), 1);
    this.detectChanges();
  }

  onSelectSound(event) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    if (!this.filesSound[this.currentPointEdit].length) {
      this.filesSound[this.currentPointEdit].push(...event.addedFiles);
    }
  }

  onRemoveSound(event) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.filesSound[this.currentPointEdit].splice(this.filesSound[this.currentPointEdit].indexOf(event), 1);
  }

  onSelectVideo(event) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.filesVideo[this.currentPointEdit].push(...event.addedFiles);
  }

  onRemoveVideo(event) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.filesVideo[this.currentPointEdit].splice(this.filesVideo[this.currentPointEdit].indexOf(event), 1);
  }

  addVideo(type: any) {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.videoType[this.currentPointEdit] = type;
  }

  onDeleteVideo() {
    if (!this.activePointsAndVariations[this.currentPointEdit].auth) return;
    this.videoCode[this.currentPointEdit] = '';
    this.videoType[this.currentPointEdit] = '';
  }

  detectChanges() {
    this.ref.detectChanges();
    setTimeout(() => {
      this.ref.detectChanges();
    }, 100);
  }

  async savePointThenChange(toIndex, direct?) {
    let element = this.activePointsAndVariations[toIndex];
    this.getPointsGoogleDetails(element, toIndex);
    this.isLoading = true;
    if (!this.activePointsAndVariations[this.currentPointEdit].point_id) {
      if (this.activePointsAndVariations[this.currentPointEdit].video_codes.length) {
        if (this.activePointsAndVariations[this.currentPointEdit].video_codes[0].type !== this.videoType[this.currentPointEdit] || this.activePointsAndVariations[this.currentPointEdit].video_codes[0].code !== this.videoCode[this.currentPointEdit]) {
          this.apiService.pointUnlinkVideos(this.activePointsAndVariations[this.currentPointEdit].id, this.activePointsAndVariations[this.currentPointEdit].video_codes[0].id).subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          )
        }
      }
      let video;
      if (this.videoType[this.currentPointEdit] && this.videoCode[this.currentPointEdit]) video = [{type: this.videoType[this.currentPointEdit], code: this.videoCode[this.currentPointEdit], order: '1'}];  
      
      let filesResized = [];
      for await (const file of this.files[this.currentPointEdit]) {
        await this.resizeImage(file, 1500, 1500).then(blob => {
          let resizedFile = new File([blob], file.name, {type: blob.type});
          filesResized.push(resizedFile);
        }, err => {
          console.error("Photo error", err);
        });
      };

      let medias = [...filesResized, ... this.filesSound[this.currentPointEdit]];
      if (this.activePointsAndVariations[this.currentPointEdit].medias.length) {
        this.activePointsAndVariations[this.currentPointEdit].medias.forEach(element => {
          if (!medias.some(media => media.name == element.file_name)) {
            this.apiService.unlinkMedia(element.id).subscribe(
              data => {
              },
              err => {
                console.log(err);
              }
            );
          }
        });
      }
      this.apiService.addPoint(
        this.activePointsAndVariations[this.currentPointEdit].lat,
        this.activePointsAndVariations[this.currentPointEdit].lon,
        this.titles[this.currentPointEdit],
        {
          point_id: this.activePointsAndVariations[this.currentPointEdit].id,
          googleplace_id: this.activePointsAndVariations[this.currentPointEdit].googleplace_id,
          description: this.descriptions[this.currentPointEdit],
          moreinfo: this.moreinfos[this.currentPointEdit],
          youtube: this.youtubes[this.currentPointEdit],
          medias: medias,
          video_codes: video,
          catpoint_id: this.categories[this.currentPointEdit],
          url: this.urls[this.currentPointEdit],
        }).subscribe(
        data => {
          if (direct) {
            this.updatePoint(this.activePointsAndVariations[this.currentPointEdit].id, toIndex, direct);
          } else {
            this.updatePoint(this.activePointsAndVariations[this.currentPointEdit].id, toIndex);
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      if (this.activePointsAndVariations[this.currentPointEdit].video_codes.length) {
        if (this.activePointsAndVariations[this.currentPointEdit].video_codes[0].type !== this.videoType[this.currentPointEdit] || this.activePointsAndVariations[this.currentPointEdit].video_codes[0].code !== this.videoCode[this.currentPointEdit]) {
          this.apiService.variationUnlinkVideos(this.activePointsAndVariations[this.currentPointEdit].id, this.activePointsAndVariations[this.currentPointEdit].video_codes[0].id).subscribe(
            data => {
            },
            err => {
              console.log(err);
            }
          )
        }
      }
      let video;
      if (this.videoType[this.currentPointEdit] && this.videoCode[this.currentPointEdit]) video = [{type: this.videoType[this.currentPointEdit], code: this.videoCode[this.currentPointEdit], order: '1'}];  
      this.apiService.addVariation(
        this.activePointsAndVariations[this.currentPointEdit].point_id,
        this.titles[this.currentPointEdit],
        this.activePointsAndVariations[this.currentPointEdit].lat,
        this.activePointsAndVariations[this.currentPointEdit].lon,
        {
          variation_id: this.activePointsAndVariations[this.currentPointEdit].id,
          description: this.descriptions[this.currentPointEdit],
          moreinfo: this.moreinfos[this.currentPointEdit],
          youtube: this.youtubes[this.currentPointEdit],
          medias: [...this.files[this.currentPointEdit], ... this.filesSound[this.currentPointEdit]],
          video_codes: video,
          catpoint_id: this.categories[this.currentPointEdit],
          url: this.urls[this.currentPointEdit],
        }).subscribe(
        data => {
          if (direct) {
            this.updatePoint(this.activePointsAndVariations[this.currentPointEdit].id, toIndex, direct);
          } else {
            this.updatePoint(this.activePointsAndVariations[this.currentPointEdit].id, toIndex);
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  updatePoint(id, toIndex, direct?) {
    this.apiService.getRoute(this.routeId).subscribe(
      data => {
        this.currentRoute = data.data;
        this.currentRoutePoints = this.currentRoute.points;
        let pointsAndVariations = [...this.currentRoute.points, ...this.currentRoute.variations];    
        this.activePointsAndVariations = pointsAndVariations.sort((a, b) => a.pivot.order - b.pivot.order);
        this.activePointsAndVariations[this.currentPointEdit] = this.activePointsAndVariations.find(element => element.id === id);
        this.checkIfAllDone();
        this.getQuizzs();
        if (direct) {
          this.directNavigation(direct);
        } else if (toIndex == 'next') {
          this.navigateNext();
        } else if (toIndex == 'prev') {
          this.navigatePrev();
        } else {
          this.changeCurrentPointEdit(toIndex);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  navigatePrev() {
    if (this.currentPointEdit == 0) {
      this.router.navigate(['/create-circuit-2']);
    } else {
      this.currentPointEdit = this.currentPointEdit - 1;
      document.querySelector(".create-circuit").scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.isLoading = false;
    }
  }

  navigateNext() {
    if (this.currentPointEdit == this.activePointsAndVariations.length - 1) {
      if (this.checkIfAllDone()) {
        this.router.navigate(['/create-circuit-4']);
      } else {
        document.querySelector(".create-circuit").scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.isLoading = false;
        this.toastr.warning('Tous vos points doivent contenir au moins un titre, une description et une image.', '', {
          timeOut: 8000,
        });
      }
    } else {
      this.currentPointEdit = this.currentPointEdit + 1;
      document.querySelector(".create-circuit").scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.isLoading = false;
    }
  }

  directNavigation(destination) {
    if (destination > 3) {
      if (this.checkIfAllDone()) {
        this.router.navigate(['/create-circuit-' + destination]);
      } else {
        document.querySelector(".create-circuit").scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.isLoading = false;
        this.toastr.warning('Tous vos points doivent contenir au mois un titre, une description et une image', '', {
          timeOut: 8000,
        });
      }
    } else {
      this.router.navigate(['/create-circuit-' + destination]);
    }
  }

  changeCurrentPointEdit(index) {
    this.currentPointEdit = index;
    this.isLoading = false;
    this.detectChanges();
  }

  checkIfAllDone() {
    this.checkAllDone = true;
    this.activePointsAndVariations.forEach((element, index) => {
      if (element.utilisateur_id == this.userID) {
        element.auth = 1;
        if (!element.title || !element.description || !element.medias.length) {
          this.checkAllDone = false;
        }
      } else {
        element.auth = 0;
      }
    });
    return this.checkAllDone;
  }

  async resizeImage(file:File, maxWidth:number, maxHeight:number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
          let width = image.width;
          let height = image.height;
          
          if (width <= maxWidth && height <= maxHeight) {
              resolve(file);
          }

          let newWidth;
          let newHeight;

          if (width > height) {
              newHeight = height * (maxWidth / width);
              newWidth = maxWidth;
          } else {
              newWidth = width * (maxHeight / height);
              newHeight = maxHeight;
          }

          let canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          let context = canvas.getContext('2d');

          context.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  onAddQuizz(point) {
    this.isLoading = true;
    if (point.point_id) {
      this.apiService.addQuizz(this.routeId, point.point_id, 1, {variation_id: point.id}).subscribe(
        data => {
          this.apiService.getQuizz(data.data.quizz_id).subscribe(
            data => {
              point.quizz = data.data[0];
              this.isLoading = false;
            },
            err => {
              console.log(err);
            }
          );
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.apiService.addQuizz(this.routeId, point.id, 1).subscribe(
        data => {
          this.apiService.getQuizz(data.data.quizz_id).subscribe(
            data => {
              point.quizz = data.data[0];
              this.isLoading = false;
            },
            err => {
              console.log(err);
            }
          );
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  onDeleteQuizz(point) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer ce quizz ?\nCette action est irréversible.')){
      this.isLoading = true;
      this.apiService.deleteQuizz(point.quizz.id).subscribe(
        data => {
          point.quizz = undefined;
          this.isLoading = false;
        },
        err => {
          console.log(err);
        }
      );
    }
  }

}