import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {

  // userID = (JSON.parse(localStorage.getItem('userData'))).id;
  userID = '1';

  userPoints;
  userPointsFiltered;
  isLoading: boolean = false;

  categories: any;
  categorie: any;

  nameSearch: any;
  citySearch: any;
  catSearch: any = 'all';
  statusSearch: any = 'all';

  constructor(private toastr: ToastrService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.isLoading = true;

    let getPoints = this.apiService.getPoints({user_id: this.userID});
    let getVariations = this.apiService.getVariations({user_id: this.userID});

    this.getCategories();

    forkJoin([getPoints, getVariations]).subscribe(results => {
      this.userPoints = [...results[0].data, ...results[1].data];
      this.userPointsFiltered = this.userPoints;
      this.isLoading = false;
    });
  }

  getCategories() {
    this.apiService.listCatpoint().subscribe(
      data => {
        this.categories = data.data;
      },
      err => {
        console.log(err);
      }
    );
  }

  onSearchNameChange(searchValue: string) {
    this.nameSearch = searchValue;
    this.applyFilters();
  }

  onSearchCityChange(searchValue: string) {
    this.citySearch = searchValue;
    this.applyFilters();
  }

  filterCat(event: any) {
    this.catSearch = event;
    this.applyFilters();
  }

  filterStatus(event: any) {
    this.statusSearch = event;
    this.applyFilters();
  }

  applyFilters() {

    if (this.nameSearch && this.citySearch && this.catSearch != 'all' && this.statusSearch != 'all') {
      //Name
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));

      //City
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));

      //Catégorie
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.catpoint_id == this.catSearch);

      //Statut
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.etat == this.statusSearch);
    } else if (this.nameSearch && this.citySearch && this.catSearch != 'all') {
      //Name
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));

      //City
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));

      //Catégorie
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.catpoint_id == this.catSearch);
    } else if (this.nameSearch && this.citySearch && this.statusSearch != 'all') {
      //Name
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));

      //City
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));

      //Statut
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.etat == this.statusSearch);
    } else if (this.citySearch && this.catSearch != 'all' && this.statusSearch != 'all') {
      //City
      this.userPointsFiltered = this.userPoints.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));

      //Catégorie
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.catpoint_id == this.catSearch);

      //Statut
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.etat == this.statusSearch);
    } else if (this.nameSearch && this.catSearch != 'all' && this.statusSearch != 'all') {
      //Name
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));

      //Catégorie
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.catpoint_id == this.catSearch);

      //Statut
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.etat == this.statusSearch);
    } else if (this.nameSearch && this.citySearch) {
      //Name
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));

      //City
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));
    } else if (this.nameSearch && this.catSearch != 'all') {
      //Name
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));

      //Catégorie
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.catpoint_id == this.catSearch);    } else if (this.nameSearch && this.statusSearch != 'all') {
    } else if (this.citySearch && this.catSearch != 'all') {
      //City
      this.userPointsFiltered = this.userPoints.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));

      //Catégorie
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.catpoint_id == this.catSearch);
    } else if (this.citySearch && this.statusSearch != 'all') {
      //City
      this.userPointsFiltered = this.userPoints.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));
      
      //Statut
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.etat == this.statusSearch);
    } else if (this.catSearch != 'all' && this.statusSearch != 'all') {
      //Catégorie
      this.userPointsFiltered = this.userPoints.filter(el => el.catpoint_id == this.catSearch);

      //Statut
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.etat == this.statusSearch);
    } else if (this.nameSearch) {
      this.userPointsFiltered = this.userPoints.filter(el => el.title.toLowerCase().includes(this.nameSearch.toLowerCase()));
    } else if (this.citySearch) {
      this.userPointsFiltered = this.userPoints.filter(el => el.city);
      this.userPointsFiltered = this.userPointsFiltered.filter(el => el.city.toLowerCase().includes(this.citySearch.toLowerCase()));
    } else if (this.catSearch != 'all') {
      this.userPointsFiltered = this.userPoints.filter(el => el.catpoint_id == this.catSearch);
    } else if (this.catSearch == 'all') {
      this.userPointsFiltered = this.userPoints;
    } else if (this.statusSearch != 'all') {
      this.userPointsFiltered = this.userPoints.filter(el => el.etat == this.statusSearch);
    } else if (this.statusSearch == 'all') {
      this.userPointsFiltered = this.userPoints;
    }
  }

  onDelete(point) {
    if(window.confirm('Êtes-vous sûr de vouloir supprimer ce point ?\nCette action est irréversible.')){
      if (point.point_id) {
        this.apiService.deletePoint(this.userID, point.point_id, point.id).subscribe(
          data => {
            this.userPoints = this.userPoints.filter(function( obj ) {
              return obj.id !== point.id && obj.point_id !== point.point_id;
            });
            this.userPointsFiltered = this.userPointsFiltered.filter(function( obj ) {
              return obj.id !== point.id && obj.point_id !== point.point_id;
            });
            this.toastr.success('Point supprimé');
          },
          err => {
            console.log(err);
            this.toastr.error('Ce point est utilisé dans un circuit ou une publication. Il ne peut pas être supprimé', '', {
              timeOut: 3000,
            });
          }
        );
      } else {
        this.apiService.deletePoint(this.userID, point.id).subscribe(
          data => {
            this.userPoints = this.userPoints.filter(function( obj ) {
              return obj.id !== point.id;
            });
            this.userPointsFiltered = this.userPointsFiltered.filter(function( obj ) {
              return obj.id !== point.id;
            });
            this.toastr.success('Point supprimé');
          },
          err => {
            console.log(err);
            this.toastr.error('Ce point est utilisé dans un circuit ou une publication. Il ne peut pas être supprimé', '', {
              timeOut: 3000,
            });
          }
        );
      }
    }
  }

  overRoute(point) {
    point.hoverRoute = true;
  }

  outRoute(point) {
    point.hoverRoute = false;
  }

  overPublication(point) {
    point.hoverPublication = true;
  }

  outPublication(point) {
    point.hoverPublication = false;
  }

}
